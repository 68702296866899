



.work {
    padding-bottom: 80px;
    h1, p, ul, li {
        color: black;
    }
    h1 {
        margin: 0;

    }
    background-color: black;
    padding-top: 6%;
    min-height: 100vh;

    &__wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        @media(max-width: 1400px){
            grid-template-columns: 1fr;
        }
        @media(max-width: 800px){
            // margin-top: 57%;
        }
        .card-3d {
            padding: 20px;
            background-color: white;
            position: relative;
            min-height: 400px!important;
            @media(max-width: 800px){
                min-height: 400px!important;
            }
            @media(max-width: 650px){
                display: none;
            }
        }
        .drums-mobi {
            position: relative;
            min-height: 450px!important;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%; 
                height: 100%; 
                object-fit: cover; 
            }
            @media(min-width: 651px){
                display: none;
            }
        }
        .card {
            padding: 20px;
            background-color: white;
            position: relative;
        }
    }
    .tv-wrapper {
        padding: 20px;
        background-color: white;
        margin-top: 20px;

        @media(max-width: 800px){
            padding: 32px;
        }
        .video-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-top: 20px;
          }
          
          .column {
            flex-basis: calc(50% - 11px);
            margin-bottom: 20px; // Adjust the margin between columns as needed
          
            @media (max-width: 1000px) {
              flex-basis: 100%;
            }
            @media (max-width: 800px) {
                margin-bottom: 0;
              }
          }
          
          .video-wrapper {
            position: relative;
            padding-bottom: 56.25%; 
            overflow: hidden;
            // @media(max-width: 1000px){
            //     margin-bottom: 0!important;
            // }

          
            iframe {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              padding-bottom: 20px;
            }
          
            // @media (max-width: 1000px) {
            //   margin-bottom: 20px; // Reduce margin bottom for mobile view
            // }
          }
          
          
          
    }
}