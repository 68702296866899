

.about {
    background-color: black;
    padding-top: 6%;
    min-height: 100vh;
    .container {
        padding-bottom: 32px;
    }
    &__body {
        background-color: black;
        padding: 20px;
        @media(max-width:700px){
            // margin-top: 70%;
        }
        .modern {
            background-color: black;
            padding: 10px;
            border-radius: 5px;
            color: white;
            margin: 24px 0;

                text-decoration: none;

        }
        p, a {
            color: white;
        }
        h1 {
            margin-top: 0;
            color: white;
        }
        .jake {
            font-size: 5rem;
            font-weight: 600;
            margin-right: 8px;

        }
    }
}