


.home {
    position: relative;


    
    .jake-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 99.8vh;
        z-index: -1;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        @media(max-width:700px){
            img {
                object-position: 95% 56%;
            }
        }
        .logo-wrapper {
            position: relative;
 
            h1 {
                font-family: $jake;
                color: $green;
                position: absolute;
                margin-left: 15%;
                @media(max-width:600px){
                    position: absolute;
                    top: -35px;
                    left: 100px;
                }
            }
        }
    }
    
}