






.contact {
    padding-bottom: 80px;
    background-color: black;
    padding-top: 6%;
    min-height: 100vh;
    @media(max-width:700px){
        // padding-top: 80%;
    }
    h1, p, ul, li {
        color: black;
    }
    h1 {
        margin: 0;
    }

    .card-top {
        background-color: white;
        padding: 20px;
        margin-bottom: 32px;
        display: flex;
        width: 50%;
        justify-content: space-around;
        align-items: center;
        @media(max-width:700px){
            margin-top: 127px;
        }
        @media(max-width: 1100px){
            width: 100%;
        }
    }
    .card {
        background-color: white;
        padding: 20px;
    }
}