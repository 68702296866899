

.navigation {
    background-color: black;

    display: flex;
    justify-content: flex-start;
    position: fixed;

    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 10;
    width: 100%;
    @media(max-width: 800px){
        margin-left: 0;
        width: 100%;
        position: relative;
        background-color: black!important;

        a {

            padding-right: 16px!important;
            display: block;
        }
    }
    @media(max-width: 800px){
        a {
            padding-right: 8px!important;
            font-size: 30px!important;
            margin-bottom: 4px;
        }
    }

    a {
        font-size: 16px;
        text-decoration: none;
        color: white;
        
        font-weight: 600;
        transition: .3s;
        padding-right: 50px;

        &:hover {
            opacity: .4;
        }
    }
    a.active {
        text-decoration: underline;
    }
}

.navigation.home-page {
    // width: 50%;
    // margin-left: 9.5%;
    background-color: transparent;
    @media(max-width: 600px){
        margin-left: 0;
        width: 100%;
        a {
            padding-right: 16px;
        }
    }
    @media(max-width: 500px){
        a {
            padding-right: 8px;
            font-size: 15px;
        }
    }
}