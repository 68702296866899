



.services {
    padding-bottom: 80px;
    background-color: black;
    padding-top: 6%;
    min-height: 100vh;
    
    h1, p, ul, li {
        color: black;
    }
    h1 {
        margin: 0;
        @media(max-width: 800px){
            margin-bottom: 32px;
        }
    }
    .card {
        padding: 20px;
        background-color: white;
        position: relative;
        @media(max-width:800px){
            // margin-top: 70%;
        }
        .card-wrapper {
            display: flex;
            margin-top: 32px;
            gap: 42px;
            @media(max-width: 800px){
                display: inline;
            }
            .left {
                p:first-child {

                    margin-top: 0;
                }
                p:last-child {
                    @media(max-width: 800px){
                        margin-bottom: 32px;
                    }

                }
            }
            img {
                width: 100%;
            }
        }
    }
}