


.upcoming-shows {
    padding-bottom: 80px;
    background-color: black;
    padding-top: 6%;
    min-height: 100vh;
    p, a {
       font-size: 17px;
    }
    h2 {
        margin-top: 0;
    }
    .show {
        background-color: white;
        padding: 30px;
        margin-bottom: 20px;
    }
    a {
        text-decoration: none;
        background-color: black;
        color: white;
        padding: 8px 10px;
        margin-top: 12px;
        transition: .3s;
        border: 2px solid black;
        &:hover {
            opacity: 1;
            background-color: white;
            color: black;
        }
    }
}